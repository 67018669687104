import React from 'react';
import ShowMoreText from 'react-show-more-text';

const Accomplishments = ({ data }) => (
  <section>
    <h1 className="section-header">Accomplishments</h1>
    {data &&
    data.map((item, i) => (
      <article className="my-5" key={`${item.company}-${i}`}>
          <ShowMoreText
            /* Default options */
            lines={2}
            more='Show more'
            less='Show less'
            anchorClass='item-sub'
            expanded={false}
          >
            {item}
          </ShowMoreText>
      </article>
    ))}
  </section>
);

export default Accomplishments;

import React from 'react';

const OpenSourceContrib = ({ data }) => (
  <section>
    <h1 className="section-header">Open Source Contribs</h1>
    <article className="my-5">
    {data.map((item, key) => (
      <span key={key}>{item.title}<br/></span>
    ))}
    </article>
  </section>
);

export default OpenSourceContrib;

import { Header, Summary, Experience, Projects, Skills, List, Education, Footer, OpenSourceContrib, Accomplishments } from "../../../../src/components";
import resume from "../../../../data/profile";
import "../../../../src/main.css";
import * as React from 'react';
export default {
  Header,
  Summary,
  Experience,
  Projects,
  Skills,
  List,
  Education,
  Footer,
  OpenSourceContrib,
  Accomplishments,
  resume,
  React
};
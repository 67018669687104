import React from 'react';

const Summary = ({ data }) => (
  <section className="py-5 border-b border-neutral-300 lg:flex items-center">
    <div className="my-5">
      <img
        className="mx-auto w-32 lg:w-full xl:w-4/5"
        src="./profile.jpeg"
        alt="profile"
      />
    </div>
    <p className="text-center tracking-wide leading-relaxed lg:text-left lg:mx-8 lg:text-base">
      {data.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
      })}
    </p>
  </section>
);

export default Summary;

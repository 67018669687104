import React from 'react';
import ShowMoreText from 'react-show-more-text';

const Experience = ({ data }) => (
  <section>
    <h1 className="section-header">Experiences</h1>
    {data &&
      data.map((item, i) => (
        <article className="my-5" key={`${item.company}-${i}`}>
          <h2 className="item-header">{item.role}</h2>
          <h3 className="item-sub">
            {item.company} | {item.start} - {item.end || 'PRESENT'}
          </h3>
          <p className="py-6">
            <ShowMoreText
              /* Default options */
              lines={2}
              more='Show more'
              less='Show less'
              anchorClass='item-sub'
              expanded={false}
            >
              {item.description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
              })}
            </ShowMoreText>
          </p>
        </article>
      ))}
  </section>
);

export default Experience;
